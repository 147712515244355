const YouTubePlayer = (props) => {
  return (
    <iframe
      className="react-player w-full p-1 md:max-h-screen border-4 border-solid rounded-lg"
      width="560"
      height="315"
      src={props.url}
      title="Youtube Player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default YouTubePlayer;
